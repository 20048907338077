import { BrowserRouter as Router } from 'react-router-dom';
import Site from './container/Site';


function App() {
  return (
    <Router>
      <Site></Site>
    </Router>
  );
}

export default App;
