import React from "react";
// SCSS // 
import './Footer.module.scss';
// MUI //
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {

    return (
        <footer>
            <div>
                <a href="https://www.linkedin.com/in/julien-maulave-8819661b6/"><LinkedInIcon /></a>
            </div>
            <p>MAULAVE JULIEN © 2022</p>
            <p>Tous droits réservés</p>
        </footer>
    )
}

export default Footer;