import React, { useState, useEffect } from "react";
// SCSS //
import Style from './Contact.module.scss';
// MUI //
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { Paper } from "@mui/material";

const Contact = () => {

    return (
        <section className={ Style.sectionContact } id='contact'>
            <h2>Contactez-moi !</h2>
            <div className={ Style.containerContact }>
                <div className={ Style.subTitle }>
                    <p>Actuellement à la recherche d'un emploi, je suis intéressé par les postes de :</p>
                    <p>Développeur full-stack - Développeur front-end - Développeur back-end</p>
                </div>
                <div className={ Style.blockContact }>
                    <Paper className={ Style.itemContact } elevation={1}  data-aos="flip-left">
                        <PhoneAndroidIcon className={ Style.purple } />
                        <p className={ Style.purple }>+33 6 66 44 82 48</p>
                    </Paper>
                    <Paper className={ Style.itemContact } elevation={1} data-aos="flip-up">
                        <MailOutlineIcon className={ Style.blue } />
                        <p className={ Style.blue }>maulave.julien74@gmail.com</p>
                    </Paper>
                    <Paper className={ Style.itemContact } elevation={1} data-aos="flip-right">
                        <p className={ Style.green }>Vous souhaitez <br />me rencontrer ?</p>
                        <p className={ Style.grey }>Je suis disponible à <b>Evian</b> et ses alentours</p>
                    </Paper>
                </div>
            </div>
        </section>
    )
}

export default Contact;