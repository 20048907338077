import React, { useEffect } from "react";
// Navigation //
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
// IMPORT //
import Header from "../components/Header/Header";
import Home from "./Home/Home";
import About from './About/About';
import Skills from './Skills/Skills';
import Experience from "./Experience/Experience";
import Project from "./Project/Project";
import Contact from "./Contact/Contact";
import Footer from "../components/Footer/Footer";
// AOS //
import Aos from "aos";

const Site = () => {

    useEffect(() => {
        Aos.init({ duration: 1000})
    }, [])

    return (
        <>
            <Header />
            <Home />
            {/*<About /> */}
            <Skills />
            <Experience />
            <Project />
            <Contact />
            <Footer />
            { /* 
            <Switch>
                <Route path="/" exact render={() => <Home />} />
                <Redirect to="/" exact render={() => <Home />} />
            </Switch>
            */ }
        </>
    )
}

export default Site;