import React, { useState, useEffect } from "react";
// SCSS //
import Style from "./Header.module.scss";
import './Header.css';
// MUI //
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// JQUERY //
import $ from 'jquery';

const navLinks = [
    {navLinkId: <InsertEmoticonIcon />, scrollToId: 'home'},
    {navLinkId: 'COMPÉTENCES', scrollToId: 'skills'},
    {navLinkId: 'PARCOURS', scrollToId: 'experience'},
    {navLinkId: 'RÉALISATION', scrollToId: 'project'},
    {navLinkId: 'CONTACT', scrollToId: 'contact'},
]

// {navLinkId: 'À PROPOS', scrollToId: 'about'}, //

const NavLink = ({ navLinkId, scrollToId, activeNavLinkId, setActiveNavLinkId }) => {
    const handleClick = () => {
        setActiveNavLinkId(navLinkId);
        document.getElementById(scrollToId).scrollIntoView({
            behavior: 'smooth', // gives an ease-in-out effect to our scroll
        });
    };

    return (
        <span 
            id={navLinkId} 
            className={activeNavLinkId === navLinkId ? 'activeClass' : ''} 
            onClick={handleClick}
        >
            {navLinkId}
        </span>
    );
};

const Header = () => {

    /* -------------------------------------------------------------------------- */
    /*                                    STATE                                   */
    /* -------------------------------------------------------------------------- */

    // Lien de navigation pour le Menu //
    const [open, setOpen] = useState(false);
    var fullMenu = document.getElementsByClassName('fullMenu');

    // Ouverture et fermeture du Menu mobile //
    useEffect(() => {
        if(!windowMatches.matche) {
            if(open === true) {
                fullMenu[0].style.left = '0%';
                
            } else {
                fullMenu[0].style.left = '100%';
            }
        }
    }, [open, fullMenu])

    /* -------------------------------------------------------------------------- */
    /*                     CLICK BUTTON BURGER MENU ANIMATION                     */
    /* -------------------------------------------------------------------------- */

    $('button').on('click', function(){
        $('body').toggleClass('open');
    });

    /* -------------------------------------------------------------------------- */
    /*                                    LIEN                                    */
    /* -------------------------------------------------------------------------- */

    const [activeNavLinkId, setActiveNavLinkId] = useState('');

    /* -------------------------------------------------------------------------- */
    /*                                   WINDOW                                   */
    /* -------------------------------------------------------------------------- */
    
    /* Window Change */
    const [windowMatches, setWindowMatches] = useState({
        matche: window.matchMedia('(min-width: 768px)').matches
    });

    useEffect(() => {
        const handler = (e) => setWindowMatches({ matche: e.matches });
        window.matchMedia('(min-width: 768px)').addEventListener('change', handler);
        
    }, [windowMatches])

    /* -------------------------------------------------------------------------- */
    /*                              NAVBAR ANIMATION                              */
    /* -------------------------------------------------------------------------- */

    useEffect(() => {
        if(windowMatches.matche === true) {

            var prevScrollpos = window.pageYOffset;
            window.onscroll = function() {
            var currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos) {
                document.getElementById("navbar").style.top = "0";
            } else {
                document.getElementById("navbar").style.top = "-70px";
            }
            prevScrollpos = currentScrollPos;
            }
        } else {
            window.onscroll = function() {
            var currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos) {
                document.getElementById("navbar").style.top = "0";
            } else {
                document.getElementById("navbar").style.top = "0px";
            }
            prevScrollpos = currentScrollPos;
            }
        }
    }, [windowMatches.matche])

    return (
        <header className={ Style.header }>
            <nav id="navbar">
                {windowMatches.matche ? (
                    <>
                        {navLinks.map(({navLinkId, scrollToId, index}) =>
                            <NavLink 
                                key={ scrollToId }
                                navLinkId={navLinkId} 
                                scrollToId={scrollToId} 
                                activeNavLinkId={activeNavLinkId}
                                setActiveNavLinkId={setActiveNavLinkId} 
                            />
                        )}
                    </>
                ) : (
                    <>
                        <div className={ Style.navMobile } onClick={() => setOpen(!open)}>
                            <MenuRoundedIcon />
                        </div>
                        <div className='fullMenu'>
                            <div>
                                {navLinks.map(({navLinkId, scrollToId, index}) =>
                                    <NavLink 
                                        key={ scrollToId }
                                        navLinkId={navLinkId} 
                                        scrollToId={scrollToId} 
                                        activeNavLinkId={activeNavLinkId}
                                        setActiveNavLinkId={setActiveNavLinkId} 
                                    />
                                )}
                                <span onClick={() => setOpen(!open)}>
                                    <CloseRoundedIcon />
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </nav>
        </header>
    )
}

export default Header;